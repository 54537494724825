<template>
  <nav class="navbar">
    <!-- Logo -->
    <div class="header-menu">
      <img src="/assets/images/logo.png" alt="Logo" class="logo" style="position:absolute;top:10px;left:80px;width:auto;height:60px;object-fit:contain;z-index:1000;" />

      <!-- Navigation Links -->
      <ul class="navigation-links hidden md:flex">
        <li v-for="link in links" :key="link.text">
          <a :href="link.href" class="nav-link">
            {{ link.text }}
          </a>
        </li>
      </ul>
    </div>



    <!-- Buttons -->
    <div class="navbar-buttons">
      <button class="btn-outline" @click="redirectToLoginPage">
        Sign Up / Log in
      </button>
      <button class="btn-primary" @click="redirectToJoinAsServiceProvider">
        Join as Service Provider
      </button>
    </div>
  </nav>
</template>
<script>
export default {
  name: "AppNavbar",
  data() { 
    return {
      links: [
  { text: "Homepage", href: "/#homepage" },
  { text: "Place Order", href: "/#place-order" },
  { text: "Service", href: "/#service" },
  { text: "Operation Mode", href: "/#operation-mode" },
  { text: "Insights", href: "/#insights" },
]

    };
  },
  methods: {
    redirectToLoginPage() {
      this.$router.push({ name: "LoginPage" });
    },
    redirectToJoinAsServiceProvider() {
      this.$router.push({ name: "JoinAsProviderPage" });
    },
  },
};
</script>

<style scoped>
/* Base styles for the navbar */
.navbar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  height: 80px;
  padding: 0 5%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px); /* Modern blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

/* Add a shrink effect on scroll */
.navbar.scrolled {
  height: 70px;
  padding: 0 3%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Logo styles */
.logo {
  height: 50px;
  width: auto;
  transition: height 0.3s ease-in-out;
}

.navigation-links {
  display: flex;
  align-items: center;
  gap: 80px;
  justify-content: flex-start; /* Align to the start */
  margin-left: 200px; /* Push to the right dynamically */
  padding-right: 5%; /* Add padding to the right for spacing */
}

.nav-link {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #141414;
  position: relative;
  transition: color 0.3s, transform 0.3s ease-in-out;
  white-space: nowrap;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #0f9d51;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.nav-link:hover {
  color: #0f9d51;
  transform: scale(1.05);
}

.nav-link:hover::after {
  width: 100%;
  left: 0;
}

.navbar-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.btn-outline, .btn-primary {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-outline {
  border: 1px solid #0f9d51;
  background-color: transparent;
  color: #0f9d51;
}

.btn-outline:hover {
  background-color: #0f9d51;
  color: white;
}

.btn-primary {
  background-color: #0f9d51;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #0b7a3e;
}

/* Responsive design for smaller screens */
@media (max-width: 992px) {
  .navbar {
    flex-wrap: wrap;
    padding: 0 3%;
  }

  .navigation-links {
    gap: 20px;
  }

  .btn-outline, .btn-primary {
    padding: 8px 15px;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .header-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .navigation-links {
    display: none; /* Hide links initially */
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
  }

  .navigation-links.active {
    display: flex; /* Show links when active */
  }

  .btn-outline, .btn-primary {
    width: 100%;
    max-width: 250px;
  }
}

@media (max-width: 576px) {
  .logo {
    height: 40px;
  }

  .btn-outline, .btn-primary {
    font-size: 12px;
    padding: 10px;
  }

  .nav-link {
    font-size: 13px;
  }
}

/* Add a creative mobile menu toggle */
.mobile-menu-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 101;
  }
}
</style>
