<template>
  <div class="order-section">
    <div  class="location-input">
      <label for="origin" class="floating-label">Origin</label>
      <div class="input-container">
        <span class="location-icon"><i class="fa fa-map-marker-alt" aria-hidden="true"></i></span>
        <input type="text" id="origin" v-model="origin" placeholder="Enter Collect Location">
      </div>
    </div>
    <div class="location-input">
      <label for="destination" class="floating-label">Destination</label>
      <div class="input-container">
        <span class="location-icon"><i class="fa fa-map-marker-alt" aria-hidden="true"></i></span>
        <input type="text" id="destination" v-model="destination" placeholder="Enter Deliver Location">
      </div>
    </div>
    <div class="location-input">
      <label for="vehicleChoice" class="floating-label">Service Type</label>
      <div class="input-container">
        <span class="location-icon"><i class="fa fa-plane" aria-hidden="true"></i></span>
        <input type="text" id="vehicleChoice" v-model="vehicleChoice" placeholder="Select Vehicle Type" >
      </div>
    </div>
    <div class="location-input">
      <label for="numPackages" class="floating-label">Number of Package</label>
      <div class="input-container">
        <span class="location-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></span>
        <input type="text" id="numPackages" v-model="numPackages" placeholder="Within 20*20*15cm,2Kg Per Package" >
      </div>
    </div>
    <button class="check-price-button" @click="checkPrice">
      Check Price
    </button>
  </div>
</template>

<script>
export default {
  name: "AppPlaceOrder",
  data() {
    return {
      origin: "",
      destination: "",
      vehicleChoice: "",
      numPackages: ""
    };
  },
  methods: {
    checkPrice() {
      console.log(
          `Origin: ${this.origin}, Destination: ${this.destination}, Vehicle: ${this.vehicleChoice}, Packages: ${this.numPackages}`
      );
    }
  }
};
</script>

<style scoped>
.order-section {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  box-shadow: 0px 0px 25px 0px rgba(151, 151, 151, 0.2);
  border-radius: 20px;
  overflow: hidden;
  padding: 20px; /* Add padding to the container */
  align-items: center; /* Align items vertically */
}

.location-input {
  flex: 1 0 200px; /* Allow inputs to shrink but not grow beyond 200px */
}

.input-container {
  position: relative;
  display: flex;      /* Make container a flexbox */
  align-items: center; /* Align icon and input vertically */
  border: darkgray solid 1px;
  border: 1px solid #979797;
  border-radius: 12px;
}


.location-icon {
  font-size: 1rem;
  margin-left: 10px;       /* Standard padding */
  color: #0F9D51;
  margin-right: 10px;    /* Add some space between icon and input */
}

input[type="text"] {  /* Target only text inputs */
  width: 100%;          /* Make inputs take the available width */
  height: 40px;        /* Consistent height for all inputs */
  font-size: 0.7rem;     /* Standard font size */
  color: #888;
  outline: none;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.floating-label {
  position: absolute;
  top: -12px;           /* Adjust top positioning */
  left: 10px;           /* Adjust left positioning */
  font-size: 0.8rem;
  color: #888;
  background-color: white;
  padding: 0 0.2rem;
  pointer-events: none;
  transition: all 0.2s ease;
}

/* Simplified floating label styles */
input:focus + .floating-label,
input:not(:placeholder-shown) + .floating-label {
  top: -20px;
  left: 10px;
  font-size: 0.8rem;
}



.check-price-button {
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  padding: 15px 30px;    /* Adjust padding for better look */
  border-radius: 12px;
  background: #0F9D51;
  color: #FFFFFF;
  white-space: nowrap;  /* Prevent text from wrapping */
  cursor: pointer;      /* Indicate it's clickable */
  border: none;          /* Remove default button border */
  flex: 0 0 258px; /* ensure button doesn't shrink or grow */
  height: 40px;
  line-height: 10px;
}
</style>
