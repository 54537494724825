<template>
   <AppNavbar />
 
    <div class="about-us-container">
      <!-- Line above About Us -->
      <div class="line"></div>
  
      <!-- About Us Section -->
      <div class="about-us-text">
        <h2>About Us</h2>
      </div>
  
      <!-- Our Service Section -->
      <div class="our-service-container">
        <div class="gradient-line"></div>
        <h2 class="section-title">Our Service</h2>
        <div class="card-container">
          <div class="card-inner-container">
            <div v-for="(stat, index) in stats" :key="index" class="stat-card">
              <div class="icon-container">
                <img :src="stat.icon" :alt="stat.title + ' Icon'" class="stat-icon" />
              </div>
              <div class="stat-details">
                <h3 class="stat-value">{{ stat.value }}</h3>
                <p class="stat-title" v-html="stat.title"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Image Section Below Our Service -->
      <div class="image-container">
        <img src="/assets/images/video 1 1.png" alt="Service Image" class="service-image" />
      </div>
  
      <!-- Line above About Us -->
      <div class="line"></div>
  
      <!-- About Us Section -->
      <div class="about-us-text">
        <h2>Meet our Team</h2>
      </div>
  
      <!-- Image and Text Section -->
      <div class="image-text-container">
        <img src="/assets/images/Group 1688.png" alt="Tech Team Image" class="tech-image" />
        <div class="text-container">
          <img src="/assets/images/Group 1485.png" alt="Quotation Start" class="quotation-start" />
          <p class="tech-text">
            <span style="font-family: Inter; font-size: 40px; font-weight: 400; line-height: 48.41px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #0F9D51;">
              Our tech team
            </span>
            is the driving force <br /> behind the innovation and efficiency of our logistics <br /> service platform. Comprised of expert developers, <br />system architects, and engineers, the team is <br /> dedicated to creating a seamless, reliable, and <br /> scalable platform that connects businesses and <br /> logistics providers. They specialize in cutting-edge <br /> technologies, including real-time tracking, AI-driven <br />route optimization, and secure data integration, <br />ensuring that our platform delivers unparalleled <br />performance and user experience. By continuously <br /> innovating and adapting to market demands, our tech <br /> team empowers users with tools that make logistics <br /> faster, smarter, and more efficient.
          </p>
          <img src="/assets/images/Group 1484.png" alt="Quotation End" class="quotation-end" />
        </div>
      </div>
  
 <!-- Swapped Image and Text Section (Text First, Then Image) -->
<div class="image-text-container-swapped">
    <img src="/assets/images/Group 1688.png" alt="Tech Team Image" class="techs-image" />
    <div class="text-container">
      <img src="/assets/images/Group 1485.png" alt="Quotation Start" class="quotation-start" />
      <p class="tech-text">
        <span style="font-family: Inter; font-size: 40px; font-weight: 400; line-height: 48.41px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #0F9D51;">
          Our customer Support
        </span>
      is the backbone <br> of user satisfaction for our logistics service platform. <br>Trained in understanding the unique needs of <br> businesses and logistics providers, our team is <br> equipped to handle inquiries, troubleshoot issues, and <br>provide expert guidance to ensure smooth operations. <br>With a commitment to fast response times and <br>personalized assistance, we aim to build trust and <br>reliability with our users. Whether it’s resolving technical <br> challenges or helping users optimize their experience on <br> the platform, our customer support team is always here <br>to help, ensuring uninterrupted and efficient logistics <br> operations.
      </p>
      <img src="/assets/images/Group 1484.png" alt="Quotation End" class="quotation-end" />
    </div>
   
  </div>


<!-- New Container Section -->
<div class="flex flex-col items-center w-[314px] h-[303.9px] gap-2 mt-[100px] mx-auto">
  <img src="/assets/images/Group 1499.png" alt="Unlock Potential" class="w-[60px] h-[60px] p-2" />

  <p class="w-[269px] text-[22px] font-semibold text-[#363636] leading-[26.63px] mt-2">
    &nbsp; Unlock your full potential
  </p>
  <p class="w-[269px] text-[20px] font-normal text-[#878787] leading-[24.2px] mt-1  whitespace-nowrap">
    Our Mission is Your Achievement
  </p>

  <button 
    class="custom-button mt-2" 
    @click="goToLoginPage">
    Get Started
  </button>
</div>

  

<!-- Footer Image -->
<div class="w-full mt-[100px]">
    <img src="/assets/images/Group 1699.png" alt="Footer Image" class="w-full h-auto" />
</div>


  
    </div>
    <div style="display: flex; flex-direction: column; min-height: 250vh;">
      <!-- Main content goes here (if any) -->
      <div style="flex-grow: 1;"></div>
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppNavbar from "@/components/Navbar.vue";
  import AppFooter from "@/components/Footer.vue"
  export default {
    name: "AboutUsPage",
    components: {
    AppNavbar,
    AppFooter,
    },
    data() {
      return {
        stats: [
          {
            icon: '/assets/images/Frame.png',
            value: '1,500,000',
            title: `Number of residents cover <br> in service region`,
          },
          {
            icon: '/assets/images/Bicycle.png',
            value: '10+',
            title: `Fast-courier company <br> integrated`,
          },
          {
            icon: '/assets/images/Truck.png',
            value: '5+',
            title: `Truck service company <br> integrated`,
          },
        ],
      };
    },
    methods: {
    goToLoginPage() {
      this.$router.push({ path: '/LoginPage' });
    }
  }
  };
  </script>
  
  <style scoped>
  .about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .line {
    width: 124px;
    height: 0px;
    border-top: 3px solid #51AB3B;
    opacity: 1;
    margin-left: -1100px;
    margin-top: 80px;
    margin-bottom: 0px;
  }
  
  .about-us-text {
    background-color: #fff;
    padding: 10px 20px;
    margin-bottom: -10px;
    margin-left: -1100px;
  }
  
  .about-us-text h2 {
    color: #3F6735;
    font-size: 2rem;
    text-align: center;
  }
  
  .our-service-container {
    background-color: #00AB4DB2;
    color: #ffffff;
    text-align: center;
    padding: 40px 20px;
    border-radius: 10px;
    margin-top: 20px;
    width: 1441px;
    position: relative;
  }
  
  .our-service-container .gradient-line {
    width: 1440.38px;
    height: 470.5px;
    position: absolute;
    top: 671px;
    left: 16747.62px;
    background: linear-gradient(to right, #00AB4D, #B2FF00);
    opacity: 1;
    z-index: -1;
  }
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 30px;
    margin-left: -800px;
    font-weight: bold;
  }
  
  .card-container {
    width: 1102.64px;
    height: 234.48px;
    margin-left: 150px;
    gap: 0;
    border-radius: 20px;
    background-color: #007f3d;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  
  .card-inner-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 190.06px;
  }
  
  .stat-card {
    width: 33%;
    height: 190.06px;
    margin-top: 20px;
    background-color: transparent;
    border-left: 1px solid #458F33;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .stat-card:first-child {
    border-left: none;
  }
  
  .icon-container {
    margin-bottom: 15px;
  }
  
  .stat-icon {
    width: 40px;
    height: 40px;
    margin-bottom: -5px;
  }
  
  .stat-details {
    font-family: 'Lato', sans-serif;
  }
  
  .stat-value {
    font-size: 2.1rem;
    font-weight: bold;
  }
  
  .stat-title {
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .image-container {
    width: 100%;
    text-align: center;
    margin-top: 60px;
  }
  
  .service-image {
    width: 973px;
    height: 596px;
    opacity: 1;
    margin: 0 auto;
  }
  
  .image-text-container {
    display: flex;
    justify-content: center;
    margin-left: 200px;
    margin-top: 40px;
    gap: 140px;
    width: 100%;
  }
  
  .image-text-container-swapped {
    display: flex;
    justify-content: center;
    margin-left: 200px;
    margin-top: 140px;
    gap: -200px;
    width: 100%;
    flex-direction: row-reverse; 
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }
  
  .quotation-start {
    width: 61.19px;
    height: 24.45px;
    opacity: 1;
    margin-left: -70px;
    margin-top: 0px;
  }
  
  .tech-text {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    text-align: left;
    text-decoration-skip-ink: none;
    width: 514px;
    margin-top: -40px;
    margin-left: 20px;
  }
  
  .quotation-end {
    width: 61.19px;
    height: 24.45px;
    opacity: 1;
    margin-left: 500px;
    margin-top: -20px;
  }
  
  .tech-image {
    width: 500px; 
    height: auto;
  }

  .techs-image {
    width: 500px;
    height: auto;
    margin-left:-900px;
  }
  /* Add this to your stylesheet or within a <style> tag */
.custom-button {
  width: 179px;
  height: 50px; /* Massively increased height */
  background-color: #51AB3B;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #388E3C;
}



  @media (max-width: 600px) {
    /* About Us Container */
    .about-us-container {
      align-items: center;
      padding: 10px;
    }
  
    /* Line */
    .line {
      width: 80px; /* Adjusted width for smaller screens */
      margin-left: 0;
    }
  
    /* About Us Text */
    .about-us-text {
      margin-left: 0;
      padding: 10px;
    }
  
    /* Our Service Container */
    .our-service-container {
      width: 100%;
      padding: 20px;
    }
    .our-service-container .gradient-line {
      width: 100%;
      top: 100%; /* Adjusted gradient for smaller screens */
    }
  
    /* Section Title */
    .section-title {
      margin-left: 0;
      text-align: center;
    }
  
    /* Card Container */
    .card-container {
    background-color: transparent; 
    display: flex;
    flex-direction: column;  /* Stack cards vertically */
    width: 100%;
    gap: 20px;  /* Add space between stacked cards */
    padding: 10px;
    margin-bottom: 500px;
    margin-left: 0px;  /* Ensure there is spacing around the cards */
    box-sizing: border-box;
}

.stat-card {
    background-color: #007f3d;
    width: 100%;  /* Ensure the card takes full width */
    min-height: 200px;  /* Set a minimum height for proper visibility */
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 10px;  /* Space between cards */
}

/* Icon adjustments for better visibility on smaller screens */
.stat-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;  /* Remove negative margin to bring it closer */
}

/* Adjust text sizes to fit better on smaller screens */
.stat-value {
    font-size: 1.2rem;  /* Adjust font size for mobile optimization */
    font-weight: bold;
    margin-bottom: 0px;  /* Remove space below the value */
    margin-top: 10px;  /* Decrease top margin to bring it closer to the icon */
}


.stat-title {
    font-size: 0.7rem;  /* Smaller title size for mobile */
    margin-top: 5px;
    text-align: center;
}

/* Adjust the card inner container layout */
.card-inner-container {
    display: flex;
    flex-direction: column;  /* Stack content vertically inside cards */
    align-items: center;
    gap: 10px;  /* Space between card elements */
    text-align: center;
}

/* Adjust the stat details section to be more readable */
.stat-details {
    font-size: 1rem;  /* Adjust font size for smaller screens */
    text-align: center;  /* Ensure text is aligned properly */
    margin-top: 10px;
}

/* Media Query for tablets and below (max-width: 768px) */
@media (max-width: 768px) {
    .card-container {
        padding: 20px;  /* Add more padding for smaller screens */
    }

    .stat-card {
        padding: 20px;  /* Increase padding for better readability */
        width: 100%;  /* Make sure cards take full width */
        margin-bottom: 20px;  /* Space between cards */
    }

    .stat-value {
        font-size: 1rem;  /* Adjust font size for mobile */
    }

    .stat-title {
        font-size: 0.9rem;  /* Slightly increase title size */
    }

    .card-inner-container {
        padding: 10px;  /* Add padding inside the cards */
        width: 100%;  /* Ensure the content inside the card fills the card */
    }
}

/* Media Query for mobile devices (max-width: 480px) */
@media (max-width: 480px) {
    .stat-card {
        width: 100%;  /* Full-width cards */
        padding: 15px;  /* Add padding for better spacing */
    }

    .stat-icon {
        width: 25px;  /* Reduce icon size further */
        height: 25px;
    }

    .stat-value {
        font-size: 0.9rem;  /* Further reduce font size for smaller screens */
    }

    .stat-title {
        font-size: 0.7rem;  /* Ensure title text is compact */
    }

    .stat-details {
        font-size: 0.9rem;  /* Adjust the details font size */
    }
}

    /* Image Container */
    .image-container {
      margin-top: 20px;
    }
    .service-image {
      width: 100%;
      height: auto;
    }
  
    /* Image Text Containers */
    .image-text-container, .image-text-container-swapped {
      flex-direction: column;
      margin-left: 0;
      margin-top: 20px;
      gap: 20px;
    }
  
    /* Quotation Marks */
    .quotation-start, .quotation-end {
      margin-left: 0;
      margin-top: 20px;
      margin-bottom:50px;
    }
  
    /* Tech Text */
    .tech-text {
      font-size: 18px;
      margin-left: 0;
      width: 100%;
    }
  
    /* Tech Images */
    .tech-image, .techs-image {
      width: 100%;
      height: auto;
      margin-left: 0;
    }
  }
  
  
  
  </style>
  