<template>
  <AppNavbar />
  <div class="page-container">
    <!-- Top Bar -->
    <div class="top-bar">
      <!-- Search Bar -->
      <div class="search-bar">
        <input
          type="text"
          class="search-input"
          placeholder="Search..."
        />
        <img
          src="/assets/images/MagnifyingGlass.png"
          alt="Search Icon"
          class="magnifying-glass-icon"
        />
      </div>

      <div class="sort-options">
        <span class="sort-text">Sort by:</span>

        <!-- New Container -->
        <div class="status-container">
          <span class="status-text">Most Popular</span>
        </div>
      </div>
    </div>
<!-- Main Content (First Container) -->
<div class="content-container">
  <!-- New Container (First) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_FWoq_ldWlNQ.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur pulvinar aliquam lectus, non blandit erat mattis vitae.
      </p>
      <p class="sub-text">
        Mauris scelerisque odio id rutrum volutpat. Pellentesque urna odio,
        vulputate at tortor vitae, hendrerit blandit lorem.
      </p>
    </div>
  </div>

  <!-- Button Container (First) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>

  <!-- New Container (Second) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_LfSuZbuqzco.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact1.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur massa orci, consectetur et blandit ac, auctor et tellus.
      </p>
      <p class="sub-text">
        Pellentesque vestibulum lorem vel gravida aliquam. Morbi porta, odio id suscipit mattis, risus augue condimentum purus.
      </p>
    </div>
  </div>

  <!-- Button Container (Second) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>
</div>




<!-- second container  -->


<!-- Main Content (First Container) -->
<div class="contenttwo-container">
  <!-- New Container (First) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_64YrPKiguAE.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact3.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur pulvinar aliquam lectus, non blandit erat mattis vitae. 
      </p>
      <p class="sub-text">
        Mauris scelerisque odio id rutrum volutpat. Pellentesque urna odio, vulputate at tortor vitae, hendrerit blandit lorem. 
      </p>
    </div>
  </div>

  <!-- Button Container (First) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>

  <!-- New Container (Second) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_jXd2FSvcRr8.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact4.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur massa orci, consectetur et blandit ac, auctor et tellus.
      </p>
      <p class="sub-text">
        Pellentesque vestibulum lorem vel gravida aliquam. Morbi porta, odio id suscipit mattis, risus augue condimentum purus.
      </p>
    </div>
  </div>

  <!-- Button Container (Second) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>
</div>




<!-- THIRD CONTAIONER  -->
<!-- Main Content (First Container) -->
<div class="contentthree-container">
  <!-- New Container (First) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_HOrhCnQsxnQ.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact5.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur pulvinar aliquam lectus, non blandit erat mattis vitae. 
      </p>
      <p class="sub-text">
        Mauris scelerisque odio id rutrum volutpat. Pellentesque urna odio, vulputate at tortor vitae, hendrerit blandit lorem. 
      </p>
    </div>
  </div>

  <!-- Button Container (First) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>

  <!-- New Container (Second) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_OgvqXGL7XO4.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact7.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur massa orci, consectetur et blandit ac, auctor et tellus.
      </p>
      <p class="sub-text">
        Pellentesque vestibulum lorem vel gravida aliquam. Morbi porta, odio id suscipit mattis, risus augue condimentum purus.
      </p>
    </div>
  </div>

  <!-- Button Container (Second) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>
</div>


<!-- FOURTH CONTAINER  -->
<!-- Main Content (First Container) -->
<div class="contentfour-container">
  <!-- New Container (First) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_RYyr-k3Ysqg.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact8.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur pulvinar aliquam lectus, non blandit erat mattis vitae. 
      </p>
      <p class="sub-text">
        Mauris scelerisque odio id rutrum volutpat. Pellentesque urna odio, vulputate at tortor vitae, hendrerit blandit lorem. 
      </p>
    </div>
  </div>

  <!-- Button Container (First) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>

  <!-- New Container (Second) -->
  <div class="inner-container">
    <!-- Image -->
    <img
      src="/assets/images/unsplash_EhTcC9sYXsw.png"
      alt="Sample Image"
      class="inner-image"
    />
    <!-- Below Image Container -->
    <div class="below-image-container">
      <img
        src="/assets/images/FunFact9.png"
        alt="Sample Image"
      />
      <!-- Text Below Image -->
      <p class="main-text">
        Curabitur massa orci, consectetur et blandit ac, auctor et tellus.
      </p>
      <p class="sub-text">
        Pellentesque vestibulum lorem vel gravida aliquam. Morbi porta, odio id suscipit mattis, risus augue condimentum purus.
      </p>
    </div>
  </div>

  <!-- Button Container (Second) -->
  <div class="button-container">
    <button class="tertiary-button">
      Read more
    </button>
  </div>
</div>

<!-- Pagination Component -->
<div class="pagination-container">
  <b-pagination
    v-model="currentPage"
    pills
    :total-rows="rows"
    :per-page="5"
    style="
      --bs-pagination-bg: #3ab81a; /* Background for pagination pills */
      --bs-pagination-color: #fff; /* Text color */
      --bs-pagination-border-radius: 50px; /* Rounded pills */
      --bs-pagination-hover-bg: #2c9b14; /* Hover background */
      --bs-pagination-hover-color: #fff; /* Hover text color */
      --bs-pagination-active-bg: #267611; /* Active background */
      --bs-pagination-active-color: #fff; /* Active text color */
      --bs-pagination-border-color: transparent; /* Remove border */
    "
  ></b-pagination>
</div>


<div class="new-container">
  <img
      src="/assets/images/Frame 446.png"
      alt="Sample Image"
    />
</div>





  </div>
  <div style="display: flex; flex-direction: column; min-height: 250vh;">
    <!-- Main content goes here (if any) -->
    <div style="flex-grow: 1;"></div>
    <AppFooter />
  </div>
  
</template>



<script>
import AppNavbar from "../components/Navbar.vue";
import AppFooter from "../components/Footer.vue";

export default {
  name: "UpdatedPage",
  components: {
    AppNavbar,
    AppFooter,
  },
  data() { 
    return {
      rows: 100, 
      currentPage: 1,
    };
  },
};
</script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600&display=swap');
  
 /* Page Container */
 .page-container {
  width: 920px;
  position: relative;
  top: 114px;
  left: 50%; /* Center horizontally relative to the viewport */
  transform: translateX(-50%); /* Adjust for element's width */
  display: flex;
  flex-direction: column;
  gap: 40px;
  opacity: 1; /* Adjust opacity as required */
  background-color: #FFFFFF;
  padding: 24px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.01);

}


  
  /* Top Bar */
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 872px;
    height: auto;
    gap: 0px;
    opacity: 1;
  }
  
  /* Search Bar */
  .search-bar {
    display: flex;
    align-items: center;
    width: 400px;
    height: 44px;
    padding: 12px 16px;
    gap: 8px;
    border-radius: 2px 0 0 0;
    border: 1px solid #E4E7E9;
    background-color: #FFFFFF;
  }
  
  .search-input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: 'Public Sans', sans-serif;
    color: #191C1F;
  }
  
  .magnifying-glass-icon {
    width: 20px;
    height: 20px;
    opacity: 1;
  }
  
  /* Sort Options */

.sort-options {
    display: flex;
    align-items: center;
    gap: 22px;
  }
  
  /* New Status Container */
  .status-container {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 44px;
    padding: 12px 16px;
    gap: 8px;
    border-radius: 2px 0 0 0;
    border: 1px solid #E4E7E9;
    background: #FFFFFF;
    opacity: 1; /* Adjust opacity as needed */
  }
  
  .status-text {
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #475156;
  }
 /* Main Content Styling */
.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 0 0;
  margin-left:22px;
  gap: 24px;
  margin-bottom:40px;
}

.contenttwo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 0 0;
  margin-left:22px;
  gap: 24px;
  margin-bottom:40px;
  margin-top:-30px;
}

.contentthree-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 0 0;
  margin-left:22px;
  gap: 24px;
  margin-bottom:40px;
  margin-top:-30px;
}

.contentfour-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 0 0;
  margin-left:22px;
  gap: 24px;
  margin-bottom:40px;
  margin-top:-30px;
}


.new-container {
  width: 1110px;
  height: 1340px;
  background-color: #fff; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-left: 0px;
  margin-top:-20px;
}









/* Inner Container */
.inner-container {
  width: 400px;
  height: 572px;
  margin-top:-50px;
  padding: 32px 0 0 0;
  gap: 24px;
  border-radius: 4px 0 0 0;
  border: 1px solid #E4E7E9;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px #0000000A;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inners-container {
  width: 400px;
  height: 572px;
  margin-top:600px;
  padding: 32px 0 0 0;
  gap: 24px;
  border-radius: 4px 0 0 0;
  border: 1px solid #E4E7E9;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px #0000000A;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Inner Image */
.inner-image {
  width: 340px;
  height: 228px;
  border-radius: 3px 0 0 0;
  opacity: 1; /* Adjust as needed */
}

/* Below Image Container */
.below-image-container {
  width: 360px;
  height: 80px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: 1; /* Adjust as needed */
}


/* Sub Text */
.sub-text {
  width: 360px;
  height: 72px;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #77878F;
}


/* Button Container */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 400px;
}

/* Tertiary Button */
.tertiary-button {
  margin-left: -630px;
  width: 162px;
  height: 48px;
  padding: 0 24px;
  gap: 8px;
  border-radius: 2px;
  border: 2px solid #FFE7D6;
  background-color: #fff;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  text-align: center; /* Aligns text horizontally */
  color: #00AB4D;
  cursor: pointer;
  letter-spacing: 0.012em;
  display: flex;
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
}

/* Hover Effect */
.tertiary-button:hover {
  background-color: #00AB4D; /* Change background to green */
  color: #fff; /* Change text color to white */
  border-color: #00AB4D; /* Optionally change the border color as well */
}


.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: -30px;
}


  </style>
  