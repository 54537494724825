<template>

  <AppNavbar/>
  <div class="login-page">
    <div class="tabs">
      <div
          class="tab sign-in-tab"
          :class="{ active: activeTab === 'signIn' }"
          @click="activeTab = 'signIn'"
      >
        Sign In
      </div>
      <div
          class="tab sign-up-tab"
          :class="{ active: activeTab === 'signUp' }"
          @click="activeTab = 'signUp'"
      >
        Sign Up
      </div>
    </div>

    <div class="input-container">
      <!-- Email Input -->
      <label for="email" class="label">Email Address</label>
      <input id="email" type="email" v-model="email" class="input">

      <!-- Password Input -->
      <div class="password-label">
        <label for="password" class="label">Password</label>
        <a href="#" @click.prevent="redirectToForgotPasswordPage" class="forgot-password">
          Forgot Password?
        </a>
      </div>
      <div class="password-input">
        <input
            :type="showPassword ? 'text' : 'password'"
            id="password"
            v-model="password"
            class="input"
        />
        <img
            src="/assets/images/Eye.png"
            alt="Show Password"
            @click="togglePasswordVisibility"
            class="eye-icon"
        />
      </div>

      <!-- Login Button -->
      <button @click="handleLogin" :disabled="loading" class="login-button">
        <span>SIGN IN</span>
        <img src="/assets/images/ArrowRight.png" alt="Arrow Icon" class="arrow-icon">
      </button>

      <!-- Separator -->
      <div class="separator">
        <div class="line"></div>
        <span>Or</span>
        <div class="line"></div>
      </div>

      <!-- Social Login Buttons -->
      <button class="social-login google">
        <img src="/assets/images/google.jpg" alt="Login with Google" class="social-icon">
        <span>Login with Google</span>
      </button>
      <button class="social-login apple">
        <img src="/assets/images/apple.jpg" alt="Login with Apple" class="social-icon">
        <span>Login with Apple</span>
      </button>
    </div>
  </div>
  <div style="display: flex; flex-direction: column; min-height: 250vh;">
    <!-- Main content goes here (if any) -->
    <div style="flex-grow: 1;"></div>
    <AppFooter />
  </div>
  
</template>

<script>
import { useToast } from 'vue-toast-notification';
import AppFooter from '@/components/Footer.vue';
import AppNavbar from '@/components/Navbar.vue';

export default {
  name: "LoginPage",
  components: {
    AppFooter,
    AppNavbar,
  },
  data() {
    return {
      activeTab: 'signIn', // Active tab state
      email: '',
      password: '',
      loading: false,
      showPassword: false,
    };
  },
  methods: {
    // Toggle password visibility
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    // Placeholder for redirecting to "Forgot Password" page
    redirectToForgotPasswordPage() {

      this.$router.push({path: '/ForgotPasswordPage'});
      // console.log("Redirect to forgot password page");
    },
    // Login handler
    async handleLogin() {
      const toast = useToast();

      // Hardcoded admin credentials for the example
      const adminEmail = 'admin@gmail.com';
      const adminPassword = 'admin';

      try {
        this.loading = true;
        if (this.email === adminEmail && this.password === adminPassword) {
          toast.success("You're successfully logged in!", { duration: 3000, position: 'top-right' });
          this.$router.push({ name: 'OrderPage' }); // Replace with your actual redirect route
        } else {
          toast.error('Invalid email or password.', { duration: 4000, position: 'top-right' });
        }
      } catch (error) {
        console.error('Error during login:', error);
        toast.error(`Error: ${error.message}`, { duration: 4000, position: 'top-right' });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@600&display=swap');

.login-page {
  width: 424px;
  height: 85vh;
  position: relative; /* 改为固定定位，使其保持相对于视口居中 */
  top: 420px; /* 设置顶部居中 */
  left: 50%; /* 设置左侧居中 */
  transform: translate(-50%, -50%); /* 通过平移实现真正的中心对齐 */
  border: 1px solid #E4E7E9;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.12);
  font-family: 'Public Sans', sans-serif;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
}

.tabs {
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #E4E7E9;
}

.tab {
  flex: 1;
  padding: 12px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.tab.active {
  border-bottom-color: #FA8232;
  color: black;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}

.label {
  font-size: 14px;
  color: #191C1F;
  margin-bottom: 4px;
}

.input {
  width: 100%;
  height: 44px;
  padding: 8px;
  border: 2px solid #E4E7E9;
  border-radius: 4px;
  box-sizing: border-box;
}

.password-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.password-input {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.login-button {
  width: 100%;
  height: 48px;
  background-color: #00AB4D;
  color: white;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #008A3D;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.separator {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.separator .line {
  flex: 1;
  height: 1px;
  background-color: #E4E7E9;
}

.separator span {
  margin: 0 12px;
  font-size: 14px;
  color: #77878F;
}

.social-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #E4E7E9;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 16px;
  background-color: white;
  cursor: pointer;
}

.social-login .social-icon {
  width: 20px;
  height: 20px;
}

.social-login span {
  flex-grow: 1;
  text-align: center;
  font-size: 14px;
  color: #475156;
}
</style>
