<template>
  <div id="homepage" class="page-container">
    <AppNavbar />
    <div style="height: 100px;"/>
    <!-- Hero Section -->
    <div class="hero-section"  :style="{ backgroundImage: `url(${GreenTrack})` }"/>

    <!-- Main Content Section -->
    <div  class="homepage-container">
      <div class="content">
        <h1>
          Quick & Comprehensive <br />
          <span class="highlight-primary">Express Delivery Platform and Logistics</span>
          <span class="highlight-secondary">Solution.</span>
        </h1>
        <p class="subtitle">
          Port ET offers quick price checks and delivery order placement, combining multiple courier companies for you to compare delivery times and services. We assure your package will be safely delivered!
        </p>
        <div class="buttons-container">
          <router-link to="/LoginPage">
  <button class="btn-primary">Try Now</button>
</router-link>


<div class="btn-secondary" @click="redirectToJoinAsProvider">
  <div class="icon-container-rt" :style="{ backgroundImage: `url(${redTrunk})` }">
  </div>
  <button class="btn-secondary-btn">
    <span class="btn-secondary-text">Join As Service Provider</span>
  </button>
</div>

        </div>
      </div>
      <!-- Right Image Section -->
      <div class="right-image">
        <img src="/assets/images/delivery.jpg" alt="Delivery Illustration" class="delivery-image" />
      </div>
    </div>

    <!-- Additional Icon -->
    <div class="icon-container" :style="{ backgroundImage: `url(${plan})` }"/>



    <div id="place-order">

    <!-- Order Section -->
    <div id="place-order" class="order-section">
      <br>  <br>
      <AppPlaceOrder />
    </div>
  </div>

    <!-- Service Section -->
    <div id="service" class="order-section">
      <AppService />
    </div>

    <!-- Operation Mode Section -->
    <div id="operation-mode" class="order-section">
      <br>   <br>
      <AppOperationMode />
    </div>

    <!-- Footer Section -->
      <AppFooter />

  </div>
</template>

<script>
import AppNavbar from "../components/Navbar.vue";
import AppPlaceOrder from "../components/PlaceOrder.vue";
import AppService from "../components/Services.vue";
import AppOperationMode from "../components/OperationMode.vue";
import AppFooter from "../components/Footer.vue";
import GreenTrack from '../assets/images/greenTrunk-1.png';
import plan from '../assets/images/plan.png';
import redTrunk from '../assets/images/redTrunk.png';
export default {
  name: "HomePage",
  components: {
    AppNavbar,
    AppPlaceOrder,
    AppService,
    AppOperationMode,
    AppFooter,
  },
  methods: {
    redirectToJoinAsProvider() {
      this.$router.push({ name: 'JoinAsProviderPage' });
    }
  },
  data() {
    return {
      GreenTrack,
      plan,
      redTrunk
    };
  },
  mounted() {
    console.log("HomePage component has been mounted!");
  },
};
</script>

<style scoped>
/* 全页容器设置，确保内容居中 */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* 让内容占满视窗高度 */
  background-color: white;
}

.hero-section {
  position: absolute;
  top: 20vh;
  left: 29vw;
  width: 67px;
  height: 67px;
  background-size: 180% 180%;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.icon-container {
  position: absolute;
  top: 97vh;
  left: 8vw;
  width: 67px;
  height: 67px;
  background-size: 180% 180%;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.icon-container-rt {
  width: 67px;
  height: 67px;
  background-size: 180% 180%;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.hero-icon {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 10;        /* 提高层级 */
}

.homepage-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 内容水平居中 */
  width: 100%;
  max-width: 70vw; /* 设置最大宽度 */
  min-height: 656px;
  gap: 20px;
  background-image: url('../assets/images/Ellipse12.png');
  background-position: -170px -200px;
}

.content {
  flex: 1;
  max-width: 596px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  text-align: left;
}

h1 {
  font-family: Lato;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: -10px;
}

.highlight-primary {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
  background: #00ab4d;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight-secondary {
  margin-left: 10px;
  background: #000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.buttons-container {
  display: flex;
  gap: 20px;
}

.btn-primary {
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  width: 134px;
  height: 57px;
  border-radius: 12px;
  background: #0f9d51; /* Original green */
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s ease; /* Smooth transition */
}

.btn-primary:hover {
  background: #0c7a3f; /* Darker green on hover */
}

.btn-secondary {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 320px;
  min-width: 320px;
  height: 50px;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-secondary-btn {
  margin-left: 20px;
  width: 230px;
  min-width: 230px;
  height: 50px;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.btn-secondary-text {
  color: darkred;
  font-weight: bold;
  text-shadow: 0 6px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.btn-secondary:hover {
  transform: scale(1.05); /* Slight zoom effect */
}




.right-image {
  flex: 1;
  max-width: 596px;
  height: 80%;
}

.delivery-image {
  width: 100%;
  height: auto;
}



.plane-icon {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  position: absolute; /* Allows precise placement */
  top: 500px; /* Adjust this value to move it further down */
  left: 50%; /* Centers the icon horizontally */
  transform: translateX(-50%); /* Ensures perfect horizontal centering */
}


.order-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 70vw;
  min-width: 1300px;
}
</style>
