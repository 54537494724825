<template>
    <div>
      <div class="relative w-[1122.54px] mx-auto mt-[-320px]">
        <div class="absolute" style="width: 107.92px; height: 22.14px; left: 80.43px; gap: 0px; font-family: 'Poppins', sans-serif; font-size: 18.45px; font-weight: 700; line-height: 27.67px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #000000;">
          <span style="background: #000000; -webkit-background-clip: text; -webkit-text-fill-color: transparent; white-space:nowrap;">BILLING</span>
        </div>
  
        <div class="absolute w-[1120.54px] border-b-[0.92px] border-[#A8A8A880] ml-[52px]" style="top: calc( 52.14px + 10px);"></div>
  
        <div class="absolute flex items-center" style="top: 0.89px; left: 940.43px; gap: 15px;">
          <span class="font-poppins text-[11.99px] font-normal leading-[17.99px] text-center" style="width: 82.09px; height: 30.44px; color: #000000; border-radius: 9.22px; box-shadow: 0px 3.69px 11.99px 0px #00000026; display: flex; align-items: center; justify-content: center;">
            <img src="/assets/images/sort.jpg" alt="Sort Icon" class="w-[14px] h-[14px] mr-[8px]" />
            Sort
          </span>
  
          <span class="font-poppins text-[11.99px] font-normal leading-[17.99px] text-center" style="width: 94.4px; height: 30.44px; color: #000000; border-radius: 9.22px; box-shadow: 0px 3.69px 11.99px 0px #00000026; display: flex; align-items: center; justify-content: center;">
            <img src="/assets/images/filter.jpg" alt="Filter Icon" class="w-[14px] h-[14px] mr-[8px]" />
            Add filter
          </span>
        </div>
  

        <div class="absolute" style="width: 899px; height: 445px; top: 100px; left: 84px; gap: 24px;">
            <div style="width: 872px; height: 24px; gap: 0px; font-family: 'Public Sans', sans-serif; font-size: 18px; font-weight: 500; line-height: 24px; text-align: left; text-decoration-skip-ink: none; color: #191C1F;">
              Billing Information
            </div>
          
            <div style="display: flex; gap: 16px;">

               
                <div style="width: auto; height: auto; gap: 16px; opacity: 0px; display: flex; flex-direction: column;">
                  <label style="width: 206px; margin-top: 20px; height: 20px; margin-bottom: -2px; opacity: 0px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #191C1F;">
                    User Name
                  </label>
                  <div style="display: flex; gap: 16px;">
                    <input type="text" placeholder="First Name" style="width: 206px; height: 44px; padding: 12px 16px; gap: 8px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #77878F;" />
                    <input type="text" placeholder="Last Name" style="width: 206px; height: 44px; padding: 12px 16px; gap: 8px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #77878F;" />
                  </div>
                </div>
              
                
                <div style="width: 428px; height: 72px; gap: 8px; opacity: 0px; display: flex; flex-direction: column;">
                  <label style="width: auto; margin-top: 20px; margin-bottom: 6px; height: auto; opacity: 0px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #191C1F;">
                    Company Name (Optional)
                  </label>
                  <input type="text" placeholder="Input Field" style="width: 100%; height: 44px; padding: 12px 16px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #77878F;" />
                </div>
              </div>


              <div style="width: auto; height: auto; gap: 16px; display: flex; flex-direction: row; align-items: flex-start;">
  

  <div style="width: auto; height: auto; gap: 16px; display: flex; flex-direction: column; align-items: flex-start;">
    <label style="width: 206px; margin-top: 20px; height: 20px; margin-bottom: -2px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #191C1F;">
      Country
    </label>
    <select style="width: 206px; height: 44px; padding: 12px 16px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; color: #77878F;">
      <option value="" disabled selected>Select Country</option>
      <option value="us">United States</option>
      <option value="ca">Canada</option>
      <option value="uk">United Kingdom</option>
      <option value="et">Ethiopia</option>

    </select>
  </div>

  <div style="width: auto; height: auto; gap: 16px; display: flex; flex-direction: column; align-items: flex-start;">
    <label style="width: 206px; margin-top: 20px; height: 20px; margin-bottom: -2px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #191C1F;">
      Region/State
    </label>
    <select style="width: 206px; height: 44px; padding: 12px 16px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; color: #77878F;">
      <option value="" disabled selected>Select Region</option>
      <option value="na">North America</option>
      <option value="eu">Europe</option>
      <option value="af">Africa</option>

    </select>
  </div>


  <div style="width: auto; height: auto; gap: 16px; display: flex; flex-direction: column; align-items: flex-start;">
    <label style="width: 206px; margin-top: 20px; height: 20px; margin-bottom: -2px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #191C1F;">
      City
    </label>
    <select style="width: 206px; height: 44px; padding: 12px 16px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; color: #77878F;">
      <option value="" disabled selected>Select City</option>
      <option value="ny">New York</option>
      <option value="lon">London</option>
      <option value="addis">Addis Ababa</option>

    </select>
  </div>
</div>



<div style="width: auto; height: auto; gap: 16px; display: flex; flex-direction: row; align-items: flex-start;">
    <div style="width: auto; height: auto; gap: 16px; opacity: 0px; display: flex; flex-direction: column;">
        <label style="width: 206px; margin-top: 20px; height: 20px; margin-bottom: -2px; opacity: 0px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #191C1F;">
        TIN NUMBER
        </label>
        <div style="display: flex; gap: 16px;">
          <input type="text"  style="width: 428px; height: 44px; padding: 12px 16px; gap: 8px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #77878F;" />
        </div>
      </div>

      <div style="width: 428px; height: 72px; gap: 8px; opacity: 0px; display: flex; flex-direction: column;">
        <label style="width: auto; margin-top: 20px; margin-bottom: 6px; height: auto; opacity: 0px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #191C1F;">
          Phone Number
        </label>
        <input type="text" style="width: 100%; height: 44px; padding: 12px 16px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #77878F;" />
      </div>

    </div>

    <div style="width: auto; height: auto; gap: 12px; display: flex; flex-direction: row; align-items: center; margin-top: 35px">

        <div style="width: 20px; height: 20px; gap: 0px; display: flex; align-items: center;">
          <input type="checkbox" style="width: 20px; height: 20px; border-radius: 2px; border: 1px solid #C9CFD2; background: #FFFFFF;" />
        </div>
    
        <div style="width: 174px; height: 20px;  display: flex; align-items: center;">
            <label style="font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #475156; text-underline-position: from-font; text-decoration-skip-ink: none; white-space: nowrap;">
                Ship into different address
              </label>
              
        </div>
      
      </div>


      <div style="width: auto; height: auto; gap: 12px; display: flex; flex-direction: row; align-items: center; margin-top: 35px;">
        <!-- Select Courier Company Dropdown -->
        <div style="width: auto; height: auto; gap: 16px; display: flex; flex-direction: column; align-items: flex-start;">
          <label style="width: 206px; margin-top: 20px; height: 20px; margin-bottom: -2px; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #191C1F;">
            SELECT COURIER COMPANY
          </label>
          <select style="width: 206px; height: 44px; padding: 12px 16px; border-radius: 2px; border: 1px solid #E4E7E9; background: #FFFFFF; font-family: 'Public Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 20px; color: #77878F;">
            <option value="" disabled selected>Select Courier company</option>
          </select>
        </div>  
      </div>
      
      <!-- Card Container -->
      <div class="absolute" style="top: 540px; left: 10px; width: 912px; height: 128px; gap: 0px; border-radius: 13.84px; border: 0.92px ; background: #FFFFFF; box-shadow: 0px 3.69px 15.68px 0px #0000001A;">
        <img src="/assets/images/product.jpg" alt="Product Image" class="absolute" style="width: 83.96px; height: 83.94px; top: 22.54px; left: 21.42px; border-radius: 13.84px; border: 0.92px solid #D0D0D0; background: #FFFFFF;" />
        
        <span class="absolute font-poppins text-[14.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 21.61px; left: 121.99px; background: #fff; color: #000;">
          EtBuy Package
        </span>
        
        <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 42.83px; left: 121.99px; color: #7B7B7B;">
          Order: 6516154854
        </span>
        
        <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 63.53px; left: 121.99px; color: #8C8C8C;">
          From: xxxxxxxxxxxxxxx
        </span>
        
        <span class="absolute font-poppins text-[14.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 84.27px; left: 121.99px; color: #1D1D1D;">
          550 Bir
        </span>
        
        <img src="/assets/images/vector28.png" alt="Vector 28" class="absolute" style="width: 50.75px; height: 20.47px; top: 64.53px; left: 470.25px;" />
        
        <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 64.53px; left: 621.99px; color: #8C8C8C;">
          To: xxxxxxxxxxxxxxx
        </span>
      </div>

      <div class="absolute" style="top: 690px; left: 10px; width: 912px; height: 128px; gap: 0px; border-radius: 13.84px; border: 0.92px ; background: #FFFFFF; box-shadow: 0px 3.69px 15.68px 0px #0000001A;">
        <img src="/assets/images/product.jpg" alt="Product Image" class="absolute" style="width: 83.96px; height: 83.94px; top: 22.54px; left: 21.42px; border-radius: 13.84px; border: 0.92px solid #D0D0D0; background: #FFFFFF;" />
        
        <span class="absolute font-poppins text-[14.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 21.61px; left: 121.99px; background: #fff; color: #000;">
          EtBuy Package
        </span>
        
        <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 42.83px; left: 121.99px; color: #7B7B7B;">
          Order: 6516154854
        </span>
        
        <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 63.53px; left: 121.99px; color: #8C8C8C;">
          From: xxxxxxxxxxxxxxx
        </span>
        
        <span class="absolute font-poppins text-[14.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 84.27px; left: 121.99px; color: #1D1D1D;">
          550 Bir
        </span>
        
        <img src="/assets/images/vector28.png" alt="Vector 28" class="absolute" style="width: 50.75px; height: 20.47px; top: 64.53px; left: 470.25px;" />
        
        <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 64.53px; left: 621.99px; color: #8C8C8C;">
          To: xxxxxxxxxxxxxxx
        </span>
      </div>
      

    
      
              
              
              
              
          </div>
          
          
      </div>
    </div>
  </template>
  
  
  
  
  
  <script>
  export default {
    
    name: 'AppBilling',
    // components: {
    //   TopBar
    // },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
  
  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
  </style>
  