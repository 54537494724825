<template>
    <div class="absolute top-[70.53px] left-[205.69px] w-[1122.54px] h-auto gap-0">
      <div class="absolute top-[47.04px] left-[80.43px] w-[239.82px] h-[22.14px] gap-0">
        <span class="font-poppins text-[11.99px] font-medium leading-[17.99px] text-left text-decoration-skip-ink-none">DASHBOARD</span>/ 
        <span class="font-poppins text-[11.99px] font-normal leading-[17.99px] text-left text-decoration-skip-ink-none">
            {{ pageTitle }}
          </span>
      </div>
  
 
      <div class="absolute top-[99px] left-[40.43px] w-[1122.82px] border-b-[0.92px] border-[#A8A8A880]"></div>
    

      <div class="absolute top-[35.97px] left-[400.25px] w-[551.58px] h-[40.58px] gap-0 rounded-[13.84px] bg-[#EFEFEF] opacity-100 flex items-center">
        <img 
          src="/assets/images/search.png" 
          alt="Search Icon" 
          class="w-[20.29px] h-[20.29px] ml-[8px]"
        />
        <span class="font-poppins text-[11.99px] font-normal leading-[17.99px] text-left text-decoration-skip-ink-none text-[#00000099] ml-[8px]">Search order, items..</span>
      </div>

      <div class="absolute top-[35.97px] left-[400.25px] w-[551.58px] h-[40.58px] gap-0 rounded-[13.84px] bg-[#EFEFEF] opacity-100 flex items-center">
        <img 
          src="/assets/images/search.png" 
          alt="Search Icon" 
          class="w-[20.29px] h-[20.29px] ml-[8px]"
        />
        <span class="font-poppins text-[11.99px] font-normal leading-[17.99px] text-left text-decoration-skip-ink-none text-[#00000099] ml-[8px]">Search order, items..</span>
      </div>
      

      <img 
        src="/assets/images/bell.jpg" 
        alt="Bell Icon" 
        class="absolute top-[43.35px] left-[974.63px] w-[23.06px] h-[23.06px] opacity-100"
      />
      

      <img 
        src="/assets/images/profile.jpg" 
        alt="Profile Icon" 
        class="absolute top-[43.35px] left-[1020.13px] w-[23.06px] h-[23.06px] opacity-100"
      />
      
      
    </div>
  </template>
  
  
  
  
  <script>
  export default {
    name: 'TopBar',
    computed: {
    pageTitle() {

      switch (this.$route.name) {
        case 'OrderPage':
          return 'ORDERS';
        case 'AddressPage':
          return 'ADDRESS';
        case 'BillingPage':
          return 'BILLING';
        default:
          return 'UNKNOWN';
      }
    },
  },
  };
  </script>
  