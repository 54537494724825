<template>
    <div>
      <AppAddress/>

    </div>


  </template>
  
  <script>
  import AppAddress from "@/components/profile/Address.vue";

  
  export default {
    name: "AddressPage",
    components: {
        AppAddress,

    
    },
    mounted() {
      console.log("AddressPage component has been mounted!");
    },
  };
  </script>
  
  <style scoped>
  </style>
  