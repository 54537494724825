<template>
    <div>
      <AppBilling/>
    </div>
   
  </template>
  
  <script>
  import AppBilling from "@/components/profile/Billing.vue";

  
  export default {
    name: "BillingPage",
    components: {
        AppBilling,

    },
    mounted() {
      console.log("AddressPage component has been mounted!");
    },
  };
  </script>
  
  <style scoped>
  </style>
  