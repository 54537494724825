<template>
  <div>
    <AppNavbar />
    <TopBar />
    <div class="sidebar">
      <!-- User Menu Title -->
      <div class="sidebar-title">
        <span class="title-text">Dashboard Menu</span>
      </div>

      <!-- Sidebar Items -->
      <div
        v-for="(item, index) in items"
        :key="item"
        :class="[ 
          'sidebar-item', 
          activeIndexItems === index ? 'sidebar-item-active' : 'sidebar-item-inactive',
          flippedIndexItems === index ? 'flipped' : ''
        ]"
        @click="navigateTo(item, index, 'items')"
      >
        <img
          :src="'/assets/images/image 1.png'"
          alt="Sidebar Icon"
          class="sidebar-icon"
        />
        <span class="sidebar-text">{{ item }}</span>
      </div>

      <!-- Divider line -->
      <div class="sidebar-divider"></div>
    </div>
  </div>
</template>

<script>
import AppNavbar from "@/components/Navbar.vue";
import TopBar from "@/components/profile/TopBar.vue";

export default {
  name: "AppProfileSidebar",
  components: {
    AppNavbar,
    TopBar
  },
  data() {
    return {
      items: ['Orders', 'Address', 'Billing', 'Settings', 'Logout'],
      activeIndexItems: -1,
      flippedIndexItems: -1, // Track flipped item
    };
  },
  methods: {
    navigateTo(item, index, listType) {
      if (listType === 'items') {
        this.activeIndexItems = index;
        this.flippedIndexItems = this.flippedIndexItems === index ? -1 : index; // Flip on click
      }

      if (item === 'Orders') {
        this.$router.push({ name: 'OrderPage' });
      } else if (item === 'Address') {
        this.$router.push({ name: 'AddressPage' });
      } else if (item === 'Billing') {
        this.$router.push({ name: 'BillingPage' });
      } else if (item === 'Logout') {
        this.$router.push({ name: 'LoginPage' });
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

/* Font */
.font-poppins {
  font-family: 'Poppins', sans-serif;
}

/* Sidebar overall styling */
.sidebar {
  position: relative;
  top: 150px;
  left: 0;
  height: 530px;
  width: 240px; /* Increased width for more creative space */
  background-color: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12); /* More intense shadow for depth */
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 999;
  border-radius: 0 15px 15px 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  background: linear-gradient(180deg, #0A6735 10%, #0A6735 90%); /* Gradient background */
}

/* Title Section for User Menu */
.sidebar-title {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.sidebar-title:hover {
  transform: scale(1.1); /* Title grows on hover */
}

.title-text {
  font-size: 18px;
  font-weight: 400;
  color: #0A6735;
  letter-spacing: 1px;
  animation: fadeIn 1s ease;
  white-space:nowrap;
}

/* Sidebar item styles */
.sidebar-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 18px 0;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 22px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  position: relative;
}

/* Sidebar item active state */
.sidebar-item-active {
  background-color: #fff;
  color: #0A6735;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateX(5px);
  transition: transform 0.3s ease;
}

/* Sidebar item hover state (on hover or active) */
.sidebar-item:hover {
  background-color: #0A6735;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateX(5px);
  transition: transform 0.3s ease;
}



.sidebar-item-inactive:hover {
  background-color: #f5f5f5;
  transform: scale(1.02);
  transition: transform 0.2s ease-in-out;
}

/* Icon flip on active state */
.sidebar-item-active .sidebar-icon {
  transform: rotateY(180deg);
  transition: transform 0.3s ease-in-out;
}

/* Flip on click */
.flipped .sidebar-icon {
  transform: rotate(90deg); /* Rotate icon by 90 degrees */
  transition: transform 0.3s ease;
}

/* Sidebar icon styling */
.sidebar-icon {
  width: 18px;
  height: 18px;
  margin-left: 40px;
  transition: transform 0.3s ease;
}

/* Sidebar text styling */
.sidebar-text {
  flex-grow: 1;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
  color: inherit;
  transition: color 0.3s ease;
}

/* Sidebar divider */
.sidebar-divider {
  margin-top: 30px;
  border-top: 1px solid #D3D3D3;
  width: 100%;
}

/* Hover effect for items */
.sidebar-item:hover {
  background-color: rgba(10, 103, 53, 0.1); /* Slight shade */
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

/* Responsive design for mobile view */
@media (max-width: 768px) {
  .sidebar {
    width: 240px;
    padding: 18px;
  }

  .sidebar-item {
    font-size: 14px;
    padding: 14px;
  }

  .sidebar-icon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 200px;
    padding: 12px;
  }

  .sidebar-item {
    font-size: 13px;
    padding: 12px;
  }

  .sidebar-icon {
    width: 16px;
    height: 16px;
  }
}

/* Keyframes for title fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
