<template>
    <div class="h-screen bg-[#F5F5F5] flex flex-col items-center justify-center">
    
      <div class="text-center p-8 rounded-xl shadow-2xl bg-white max-w-[500px] w-full">

        <h1 class="text-[90px] font-extrabold text-[#00AB4D] leading-tight">
          404
        </h1>

        <p class="text-[20px] font-medium text-[#4B4B4B] my-4">
          Oops! The page you're looking for <br> can't be found.
        </p>
       
        <p class="text-[15px] text-[#6A6A6A] leading-[1.7] mb-6">
          It seems the link you followed is broken or the page may have moved.
          Don't worry, we'll help you get back to where you need to go.
        </p>
        

        <div class="mt-8 flex space-x-4 justify-center">
     
          <router-link 
            to="/" 
            class="bg-[#00AB4D] text-white text-[16px] font-semibold py-3 px-8 rounded-xl transform transition-all duration-300 hover:bg-[#008F3D] hover:scale-105 shadow-lg"
          >
            Go to Home
          </router-link>
          
   
          <a 
            href="mailto:support@example.com" 
            class="border-2 border-[#00AB4D] text-[#00AB4D] text-[16px] font-semibold py-3 px-8 rounded-xl transform transition-all duration-300 hover:bg-[#00AB4D] hover:text-white shadow-lg"
          >
            Contact Support
          </a>
        </div>
      </div>
  

      <div class="absolute bottom-0 w-full text-center text-[12px] text-[#A1A1A1] py-4">
        <p>© 2025 Your Brand. All rights reserved.</p>
      </div>
    </div>


  </template>
  
  <script>
  export default {
    name: "NotFoundPage",
  };
  </script>
  
  <style scoped>
  body {
    font-family: 'Poppins', sans-serif;
    background-color: #F5F5F5;
  }
  </style>
  