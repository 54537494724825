<template>
  <AppNavbar />
    <div class="services-container">
      <!-- Title Section -->
      <div class="services-title">
        <h1 class="title-text">
          <span class="title-highlight">Collaboration Mode</span>
        </h1>
        <img src="/assets/images/greenTrunk.png" alt="Track Icon" class="title-icon" />
      </div>
  
      <!-- Cards Section -->
      <div class="cards-container">
        <div class="card" v-for="(service, index) in services" :key="index">
            <div class="service-icon">
                <img 
                  :src="service.icon" 
                  :alt="`Icon for ${service.title}`" 
                  :style="{ width: '30px', height: '30px' }" 
                />
              </div>
          <p class="service-title">{{ service.title }}</p>
          <p class="service-description" v-html="service.description"></p>
        </div>
      </div>

      <div class="contact-page">
        <div class="tabs">
            <div
              class="tab contact-tab"
              :class="{ active: activeTab === 'contact' }"
              @click="activeTab = 'contact'"
            >
              Contact Information
            </div>
          
            <div class="contact-description">
              Please leave your contact and your business <br> introduction, we will contact you with an offer shortly.
            </div>
          </div>
          
    
        <div class="input-container">
          <!-- Company Name -->
          <label for="company" class="label">Company Name</label>
          <input id="company" type="text" v-model="company" class="input">
    
          <!-- Contact Name -->
          <label for="contact" class="label">Contact Name</label>
          <input id="contact" type="text" v-model="contact" class="input">
    
          <!-- Phone Number -->
          <label for="phone" class="label">Phone Number</label>
          <input id="phone" type="text" v-model="phone" class="input">
    
          <!-- Email Address -->
          <label for="email" class="label">Email Address</label>
          <input id="email" type="email" v-model="email" class="input">
    
          <!-- SEND Button -->
          <button @click="handleSend" :disabled="loading" class="send-button">
            <span>SEND</span>
            <img src="/assets/images/ArrowRight.png" alt="Arrow Icon" class="arrow-icon">
          </button>
        </div>

      </div>
<!--   

      <div class="button-container">
        <button class="request-service-button">Request Service</button>
        <button class="join-now-button">Join Now</button>
      </div> -->
    </div>
    <div style="display: flex; flex-direction: column; min-height: 250vh;">
        <!-- Main content goes here (if any) -->
        <div style="flex-grow: 1;"></div>
        <AppFooter />
      </div>
  </template>
  
  <script>
  import AppNavbar from "@/components/Navbar.vue";
  import AppFooter from "@/components/Footer.vue";
  export default {
    name: "JoinAsProviderPage",
     components: {
      AppNavbar,
    AppFooter,
  },
    data() {
      return {
        services: [
          {
            icon: '/assets/images/Rss.png',
            title: '1. Free API Integration',
            description: `We provide Free API integration for your system and our software. We will make necessary upgrades for <br> your software when necessary. Connect to our <br> interface, customers can check distance and price <br> through our interface.`
          },
          {
            icon: '/assets/images/CurrencyDollar.png',
            title: '2. Advance Payment',
            description: 'Through our system, customer will make payment to <br> you before sending out riders. You can secure the <br> payment in advance.'
          },
          {
            icon: '/assets/images/Percent.png',
            title: '3. Commission Base',
            description: `We charge no fee in advance. 
  We only charge a commission for the new order we <br> bring in through our software.
  Together, we can <br>reach more customers, and we can <br>expand the service to more areas. <br>
  Your success is our wish!`
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  /* Base styles for the services container */
  .services-container {
    display: flex;
    flex-direction: column;
    background: #F8FAFB;
    padding: 5%;
    box-sizing: border-box;
    max-width: 100%;
    gap: 20px;
    height: 1500px;
  }
  
  /* Title section styling */
  .services-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .title-text {
    font-family: 'Lato', sans-serif;
    font-size: clamp(1.5rem, 4vw, 2.5rem); /* Responsive font size */
    font-weight: 500;
    color: #333;
    text-align: center; /* Center align for smaller screens */
  }
  
  .title-highlight {
    font-weight: 700;
    border-bottom: 4px solid #00AB4D;
  }
  
  .title-icon {
    width: clamp(100px, 15vw, 160px); /* Adjust size for responsiveness */
    height: clamp(100px, 15vw, 160px);
    transition: transform 0.3s ease;
  }
  
  .title-icon:hover {
    transform: rotate(15deg); /* Hover effect on title icon */
  }
  
  /* Cards container */
  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    transition: all 0.3s ease;
  }
  
  .card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    max-width: 100%; /* Ensure cards fit within the container */
  }
  
  .card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
    transform: translateY(-5px); /* Slight upward movement on hover */
  }
  
  .card:focus {
    outline: 2px solid #0F9D51; /* Focus outline for accessibility */
  }
  
  /* Service icon inside card */
  .service-icon {
    margin-bottom: 20px;
    width: clamp(20px, 4vw, 100px); /* Increased circle size */
    height: clamp(20px, 4vw, 100px); /* Increased circle size */
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  
  .service-icon:hover {
    transform: scale(1.1); /* Scale effect on hover */
  }
  
  /* Service title inside card */
  .service-title {
    font-family: Lato, sans-serif;
    font-size: clamp(1rem, 3vw, 1.5rem); /* Responsive title font size */
    font-weight: 600;
    line-height: 1.2;
    color: #0F9D51;
    margin: 10px 0;
    transition: color 0.3s ease;
    text-align: center; /* Align center for small screens */
  }
  
  .service-title:hover {
    color: #007d33; /* Slight color change on hover */
  }
  
  /* Service description inside card */
  .service-description {
    font-family: Lato, sans-serif;
    font-size: clamp(0.9rem, 2.5vw, 1rem); /* Responsive description font size */
    line-height: 1.6;
    color: #555;
    transition: color 0.3s ease;
    text-align: justify; /* Ensure readability */
  }
  
  .service-description:hover {
    color: #333; /* Subtle color change on hover */
  }
  
  /* Button container styling */
  .button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 10px;
  }
  
  .request-service-button,
  .join-now-button {
    padding: 15px 30px;
    border-radius: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease;
  }
  
  .request-service-button {
    border: 2px solid #0F9D51;
    background-color: white;
    color: #0F9D51;
  }
  
  .join-now-button {
    background-color: #0F9D51;
    color: white;
    border: none;
  }
  
  button:hover {
    opacity: 0.85; /* Hover opacity effect */
    transform: scale(1.05); /* Slight scale effect on hover */
  }
  
  button:focus {
    outline: 2px solid #0F9D51; /* Focus outline for accessibility */
  }


  .contact-page {
    width: 424px;
    height: 85vh;
    position: relative;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #E4E7E9;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.12);
    font-family: 'Public Sans', sans-serif;
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
  }
  
  .tabs {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the tab and description */
  }
  
  .tab.contact-tab {
    flex: 1;
    padding: 12px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
  }
  
  .tab.contact-tab.active {
    border-bottom-color: #FA8232;
    color: black;
  }
  
  .contact-description {
    font-family: Public Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #5F6C72;
    margin-top: -10px; 
    margin-bottom: 25px;
  }
  
  
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
  }
  
  .label {
    font-size: 14px;
    color: #191C1F;
    margin-bottom: 4px;
  }
  
  .input {
    width: 100%;
    height: 44px;
    padding: 8px;
    border: 2px solid #E4E7E9;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .send-button {
    width: 100%;
    height: 48px;
    background-color: #00AB4D;
    color: white;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-button:hover {
    background-color: #008A3D;
  }
  
  .arrow-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
  
  .separator {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  
  .separator .line {
    flex: 1;
    height: 1px;
    background-color: #E4E7E9;
  }
  
  .separator span {
    margin: 0 12px;
    font-size: 14px;
    color: #77878F;
  }
  
  .social-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #E4E7E9;
    border-radius: 4px;
    padding: 8px 16px;
    margin-top: 16px;
    background-color: white;
    cursor: pointer;
  }
  
  .social-login .social-icon {
    width: 20px;
    height: 20px;
  }
  
  .social-login span {
    flex-grow: 1;
    text-align: center;
    font-size: 14px;
    color: #475156;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .services-container {
      padding: 30%;
    }
  
    .title-text {
      text-align: center; /* Center-align title */
      font-size: clamp(1.2rem, 4vw, 2rem);
    }
  
    .cards-container {
      display: flex;
      flex-direction: column; /* Vertical alignment for cards */
      gap: 15px; /* Increased spacing between cards */
    }
  
    .card {
      width: 100%; /* Reduce card width on smaller screens */
      margin: 0 auto; /* Center cards horizontally */
      padding: 15px; /* Reduce padding for compact layout */
    }
  
    .button-container {
      flex-direction: horizontal; /* Buttons stacked vertically */
      gap: 20px; /* Increased spacing between buttons */
    }
  
    .request-service-button,
    .join-now-button {
      width: 40%; /* Reduce button width */
      margin: 0 auto; /* Center buttons */
    }
  }
  
  @media (max-width: 576px) {
    .title-text {
      font-size: clamp(1rem, 3vw, 1.5rem);
    }
  
    .request-service-button,
    .join-now-button {
      width: 40%; /* Full width buttons */
    }
  
    .card {
      width: 50%; /* Ensure full width on very small screens */
      margin: 0; /* Remove extra margin */
    }
  }
  </style>
  
  
  