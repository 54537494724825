<template>
    <div>
      <AppProfileSidebar />
      <router-view></router-view> 
    </div>
    <div style="display: flex; flex-direction: column; min-height: 250vh;">
      <!-- Main content goes here (if any) -->
      <div style="flex-grow: 1;"></div>
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppProfileSidebar from "@/components/profile/index.vue";
  import AppFooter from "@/components/Footer.vue";
  
  export default {
    name: "ProfileSidebar",
    components: {
      AppProfileSidebar,
      AppFooter,
    },
  };
  </script>
  
  <style scoped>
  </style>
  
  