<template>
    <div>
      <AppOrder/>
    </div>


  </template>
  
  <script>
  import AppOrder from "@/components/profile/Order.vue";

  
  export default {
    name: "OrderPage",
    components: {
      AppOrder,
    },
    mounted() {
      console.log("OrderPage component has been mounted!");
    },
  };
  </script>
  
  <style scoped>
  </style>
  