<template>
  <div class="operation-mode-container" >
    <div >
      <h1 class="title-text">
        <span class="title-highlight">Operation</span> Mode
      </h1>
    </div>

    <div class="mode-section" v-for="(mode, index) in modes" :key="index" :class="{ 'reverse-layout': index % 2 === 1 }">
      <div :class="{'content-left': index === 1, 'content': index !== 1}">
        <div class="title-container">
          <img :src="mode.icon" :alt="mode.title + ' Icon'" class="mode-icon">
          <h2 class="mode-title">{{ mode.title }}</h2>
        </div>
        <p class="mode-description">{{ mode.description }}</p>
      </div>
      <div class="image-container">
        <img :src="mode.image" :alt="mode.title + ' Illustration'" class="mode-image">
      </div>
    </div>
  </div>
</template>

<script>
import one from '../assets/images/one.png';
import two from '../assets/images/two.png';
import three from '../assets/images/three.png';
import yourDeliver from '../assets/images/yourDeliver.png';
import shipPackge from '../assets/images/shipPackge.png';
import trunkService from '../assets/images/trunkService.png';


export default {
  name: "AppOperationMode",
  data() {
    return {
      modes: [
        {
          icon: one,
          title: 'Facilitate your Online Store Delivery',
          description: "If you’re currently running your store on EtBuy, you can send out your package in PORT and have the package info directly linked with the order. You and your customer can check the status of the package real-time.",
          image: yourDeliver
        },
        {
          icon: two,
          title: 'Ship your own Package',
          description: "Simply type in your address and destination, you can check different price and time needed for sending your packages. Choose your favorite supplier and click “confirm”. We provide transparent pricing and seamless online order system.",
          image: shipPackge
        },
        {
          icon: three,
          title: 'Truck Service',
          description: "Simply type in your address and destination, you will be contacted by our support team to confirm the truck with you. Tell us your demand, and we will arrange the service for you from different supplier, get quota from different suppliers within one phone call!",
          image: trunkService
        }
      ]
    };
  }
};
</script>

<style scoped>
.operation-mode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  background-image: url('../assets/images/greenTrunk.png');
  background-repeat: no-repeat;
  background-position: left top;
}


.title-text {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  text-align: center; /* Center the title */
  margin-bottom: 30px; /* Add margin below the title */
}

.title-highlight {
  font-weight: 700;
  border-bottom: 4px solid #00AB4D;
}


.mode-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;  /* Space between mode sections */
  width: 100%;
  max-width: 70vw; /* set a max-width for larger screens */

}


/* Reverse layout for every other section */
.reverse-layout {
  flex-direction: row-reverse;
}

.content {
  flex: 1; /* Ensure content takes up available space */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: #00AB4D solid 1px;
  border-right-color: #ffffff;
  padding: 60px 40px 60px 40px;
}

.content-left {
  flex: 1; /* Ensure content takes up available space */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: #00AB4D solid 1px;
  border-left-color: #ffffff;
  padding: 60px 40px 60px 40px;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mode-icon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  margin-right: 30px;
}

.mode-title {
  font-family: Lato;
  font-size: 1.8rem; /* Slightly smaller title */
  font-weight: 900;
  line-height: 1.2;
  background: #00AB4D;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.mode-description {
  font-family: Lato;
  font-size: 1rem;
  line-height: 1.5;
}


.image-container {
  flex: 1; /* Images take up equal space */
  max-width: 500px; /* Limit image width */

}

.mode-image {
  width: 500px;
  height: 400px;
  border-radius: 8px; /* Rounded corners for images */
  object-fit: contain;
}
</style>
