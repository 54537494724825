import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router'; 
import './assets/styles/tailwind.css'; 
import '@fortawesome/fontawesome-free/css/all.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { BootstrapVue3 } from 'bootstrap-vue-3';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";


loadFonts();

createApp(App)
  .use(vuetify)
  .use(router)
  .use(VueToast)
  .use(BootstrapVue3)
  .mount('#app');
