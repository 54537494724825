<template>
    <div>
      <div class="relative w-[1122.54px] mx-auto mt-[-320px]">
        
        <div class="absolute" style="width: 107.92px; height: 22.14px; left: 80.43px; gap: 0px; font-family: 'Poppins', sans-serif; font-size: 18.45px; font-weight: 700; line-height: 27.67px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #000000;">
          <span style="background: #000000; -webkit-background-clip: text; -webkit-text-fill-color: transparent; white-space:nowrap;">ADDRESS MANAGEMENT</span>
        </div>
  

        <div class="absolute w-[1120.54px] border-b-[0.92px] border-[#A8A8A880] ml-[52px]" style="top: calc( 52.14px + 10px);"></div>
        <div class="absolute flex items-center" style="top: 0.89px; left: 940.43px; gap: 15px;">
            <span class="font-poppins text-[11.99px] font-normal leading-[17.99px] text-center" style="width: 82.09px; height: 30.44px; color: #000000; border-radius: 9.22px; box-shadow: 0px 3.69px 11.99px 0px #00000026; display: flex; align-items: center; justify-content: center;">
       
              <img src="/assets/images/sort.jpg" alt="Sort Icon" class="w-[14px] h-[14px] mr-[8px]" />
              Sort
            </span>
          

            <span class="font-poppins text-[11.99px] font-normal leading-[17.99px] text-center" style="width: 94.4px; height: 30.44px; color: #000000; border-radius: 9.22px; box-shadow: 0px 3.69px 11.99px 0px #00000026; display: flex; align-items: center; justify-content: center;">
              <img src="/assets/images/filter.jpg" alt="Filter Icon" class="w-[14px] h-[14px] mr-[8px]" />
              Add filter
            </span>
          </div>

          <div class="absolute" style="top: 90px; left: 120px; width: 912px; height: 128px; gap: 0px; border-radius: 13.84px; border: 0.92px; background: #FFFFFF; box-shadow: 0px 3.69px 15.68px 0px #0000001A;">
         
            <img src="/assets/images/DavidJohn.jpg" alt="Product Image" class="absolute" style="width: 107.96px; height: 72.94px; top: 22.54px; left: 21.42px; border-radius: 13.84px; border: 0.92px solid #D0D0D0; background: #FFFFFF;" />
          
        
            <span class="absolute font-poppins text-[20.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 31.61px; left: 140.99px; background: #fff; color: #000;">
              David John
            </span>
          
         
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 62.83px; left: 140.99px; color: #7B7B7B;">
             My Address
            </span>
          
            
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 400.25px; color: #8C8C8C;">
            0706312495
            </span>
          
          <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 621.99px; color: #8C8C8C;">
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </span>
        
        </div>

        
        <div class="absolute" style="top: 240px; left: 120px; width: 912px; height: 128px; gap: 0px; border-radius: 13.84px; border: 0.92px; background: #FFFFFF; box-shadow: 0px 3.69px 15.68px 0px #0000001A;">
         
            <img src="/assets/images/DavidJohn2.jpg" alt="Product Image" class="absolute" style="width: 107.96px; height: 72.94px; top: 22.54px; left: 21.42px; border-radius: 13.84px; border: 0.92px solid #D0D0D0; background: #FFFFFF;" />
          
        
            <span class="absolute font-poppins text-[20.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 31.61px; left: 140.99px; background: #fff; color: #000;">
              David John
            </span>
          
         
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 62.83px; left: 140.99px; color: #7B7B7B;">
             My Address
            </span>
          
            
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 400.25px; color: #8C8C8C;">
            0706312495
            </span>
          
          <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 621.99px; color: #8C8C8C;">
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </span>
        
        </div>


        
        <div class="absolute" style="top: 390px; left: 120px; width: 912px; height: 128px; gap: 0px; border-radius: 13.84px; border: 0.92px; background: #FFFFFF; box-shadow: 0px 3.69px 15.68px 0px #0000001A;">
         
            <img src="/assets/images/DavidJohn3.jpg" alt="Product Image" class="absolute" style="width: 107.96px; height: 72.94px; top: 22.54px; left: 21.42px; border-radius: 13.84px; border: 0.92px solid #D0D0D0; background: #FFFFFF;" />
          
        
            <span class="absolute font-poppins text-[20.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 31.61px; left: 140.99px; background: #fff; color: #000;">
              David John
            </span>
          
         
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 62.83px; left: 140.99px; color: #7B7B7B;">
             My Address
            </span>
          
            
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 400.25px; color: #8C8C8C;">
            0706312495
            </span>
          
          <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 621.99px; color: #8C8C8C;">
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </span>
        
        </div>


        
        <div class="absolute" style="top: 540px; left: 120px; width: 912px; height: 128px; gap: 0px; border-radius: 13.84px; border: 0.92px; background: #FFFFFF; box-shadow: 0px 3.69px 15.68px 0px #0000001A;">
         
            <img src="/assets/images/DavidJohn4.jpg" alt="Product Image" class="absolute" style="width: 107.96px; height: 72.94px; top: 22.54px; left: 21.42px; border-radius: 13.84px; border: 0.92px solid #D0D0D0; background: #FFFFFF;" />
          
        
            <span class="absolute font-poppins text-[20.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 31.61px; left: 140.99px; background: #fff; color: #000;">
              David John
            </span>
          
         
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 62.83px; left: 140.99px; color: #7B7B7B;">
             My Address
            </span>
          
            
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 400.25px; color: #8C8C8C;">
            0706312495
            </span>
          
          <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 621.99px; color: #8C8C8C;">
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </span>
        
        </div>


        
        <div class="absolute" style="top: 690px; left: 120px; width: 912px; height: 128px; gap: 0px; border-radius: 13.84px; border: 0.92px; background: #FFFFFF; box-shadow: 0px 3.69px 15.68px 0px #0000001A;">
         
            <img src="/assets/images/DavidJohn5.jpg" alt="Product Image" class="absolute" style="width: 107.96px; height: 72.94px; top: 22.54px; left: 21.42px; border-radius: 13.84px; border: 0.92px solid #D0D0D0; background: #FFFFFF;" />
          
        
            <span class="absolute font-poppins text-[20.76px] font-semibold leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 31.61px; left: 140.99px; background: #fff; color: #000;">
              David John
            </span>
          
         
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 62.83px; left: 140.99px; color: #7B7B7B;">
             My Address
            </span>
          
            
            <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 400.25px; color: #8C8C8C;">
            0706312495
            </span>
          
          <span class="absolute font-poppins text-[14.76px] font-normal leading-[22.14px] text-left" style="width: 259.26px; height: 24.9px; top: 44.53px; left: 621.99px; color: #8C8C8C;">
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </span>
        
        </div>


  
      </div>
    </div>
  </template>
  
  
  
  
  
  <script>
  export default {
    name: 'AppAddress',

  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
  
  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
  </style>
  